// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var ReactSwipeable = require("react-swipeable");

var ReactSwipeable$1 = /* module */[];

function Swipe(Props) {
  var match = Props.nodeName;
  var nodeName = match !== undefined ? match : "div";
  var match$1 = Props.onSwipedDown;
  var onSwipedDown = match$1 !== undefined ? match$1 : (function (param) {
        return /* () */0;
      });
  var match$2 = Props.onSwipedLeft;
  var onSwipedLeft = match$2 !== undefined ? match$2 : (function (param) {
        return /* () */0;
      });
  var match$3 = Props.onSwipedRight;
  var onSwipedRight = match$3 !== undefined ? match$3 : (function (param) {
        return /* () */0;
      });
  var match$4 = Props.onSwipedUp;
  var onSwipedUp = match$4 !== undefined ? match$4 : (function (param) {
        return /* () */0;
      });
  var children = Props.children;
  return React.createElement(ReactSwipeable.Swipeable, {
              nodeName: nodeName,
              onSwipedDown: onSwipedDown,
              onSwipedLeft: onSwipedLeft,
              onSwipedRight: onSwipedRight,
              onSwipedUp: onSwipedUp,
              children: children
            });
}

var make = Swipe;

exports.ReactSwipeable = ReactSwipeable$1;
exports.make = make;
/* react Not a pure module */
