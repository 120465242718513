// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var S1Png = require("../img/s1.png");
var S2Png = require("../img/s2.png");
var S3Png = require("../img/s3.png");
var S4Png = require("../img/s4.png");
var Aa1Png = require("../img/aa1.png");
var Aa2Png = require("../img/aa2.png");
var Aa3Png = require("../img/aa3.png");
var Aa4Png = require("../img/aa4.png");
var Aa5Png = require("../img/aa5.png");
var Aa6Png = require("../img/aa6.png");
var Aa7Png = require("../img/aa7.png");
var Bb1Png = require("../img/bb1.png");
var Bb2Png = require("../img/bb2.png");
var Bb3Png = require("../img/bb3.png");
var Bb4Png = require("../img/bb4.png");
var CmrPng = require("../img/cmr.png");
var LeftSvg = require("../img/left.svg");
var RightSvg = require("../img/right.svg");
var GitlabSvg = require("../img/gitlab.svg");
var ExtLinkSvg = require("../img/extLink.svg");
var KeybasePng = require("../img/keybase.png");
var ChevronXSvg = require("../img/chevronX.svg");
var MastodonSvg = require("../img/mastodon.svg");
var ChevronUpSvg = require("../img/chevronUp.svg");
var Post1shot1Jpg = require("../img/post1shot1.jpg");

var cameroon = CmrPng;

var gitlabIcon = GitlabSvg;

var keybaseIcon = KeybasePng;

var mastodonIcon = MastodonSvg;

var extLink = ExtLinkSvg;

var leftArrow = LeftSvg;

var rightArrow = RightSvg;

var chevronUp = ChevronUpSvg;

var chevronX = ChevronXSvg;

var post1shot1 = Post1shot1Jpg;

var aa1 = Aa1Png;

var aa2 = Aa2Png;

var aa3 = Aa3Png;

var aa4 = Aa4Png;

var aa5 = Aa5Png;

var aa6 = Aa6Png;

var aa7 = Aa7Png;

var bb1 = Bb1Png;

var bb2 = Bb2Png;

var bb3 = Bb3Png;

var bb4 = Bb4Png;

var testAs1 = S1Png;

var testAs2 = S2Png;

var testAs3 = S3Png;

var testAs4 = S4Png;

exports.cameroon = cameroon;
exports.gitlabIcon = gitlabIcon;
exports.keybaseIcon = keybaseIcon;
exports.mastodonIcon = mastodonIcon;
exports.extLink = extLink;
exports.leftArrow = leftArrow;
exports.rightArrow = rightArrow;
exports.chevronUp = chevronUp;
exports.chevronX = chevronX;
exports.post1shot1 = post1shot1;
exports.aa1 = aa1;
exports.aa2 = aa2;
exports.aa3 = aa3;
exports.aa4 = aa4;
exports.aa5 = aa5;
exports.aa6 = aa6;
exports.aa7 = aa7;
exports.bb1 = bb1;
exports.bb2 = bb2;
exports.bb3 = bb3;
exports.bb4 = bb4;
exports.testAs1 = testAs1;
exports.testAs2 = testAs2;
exports.testAs3 = testAs3;
exports.testAs4 = testAs4;
/* cameroon Not a pure module */
