// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var Images = require("./Images.js");

var init_002 = /* screenShots : array */[
  /* record */[
    /* alt */"screenShot1",
    /* src */Images.bb1
  ],
  /* record */[
    /* alt */"screenShot2",
    /* src */Images.bb2
  ],
  /* record */[
    /* alt */"screenShot3",
    /* src */Images.bb3
  ],
  /* record */[
    /* alt */"screenShot4",
    /* src */Images.bb4
  ]
];

var init = /* record */[
  /* description */"A starter-kit web app with the typical re-useable elements I need in ReasonML.",
  /* name */"template-1",
  init_002,
  /* sourceCode */"https://gitlab.com/dpeyou/init-web.re",
  /* url */"https://init-web.herokuapp.com/"
];

var w_002 = /* screenShots : array */[
  /* record */[
    /* alt */"screenShot1",
    /* src */Images.aa2
  ],
  /* record */[
    /* alt */"screenShot2",
    /* src */Images.aa1
  ],
  /* record */[
    /* alt */"screenShot3",
    /* src */Images.aa3
  ],
  /* record */[
    /* alt */"screenShot4",
    /* src */Images.aa4
  ],
  /* record */[
    /* alt */"screenShot5",
    /* src */Images.aa5
  ],
  /* record */[
    /* alt */"screenShot6",
    /* src */Images.aa6
  ],
  /* record */[
    /* alt */"screenShot7",
    /* src */Images.aa7
  ]
];

var w = /* record */[
  /* description */"This app helps me keep up with the upcoming fixtures of select leagues (5-6), including the German Bundesliga & English Premier League.",
  /* name */"Mini sports app",
  w_002,
  /* sourceCode */"https://gitlab.com/dpeyou/w",
  /* url */"https://thedub.herokuapp.com/"
];

var testA_002 = /* screenShots : array */[
  /* record */[
    /* alt */"testA_screenShot1",
    /* src */Images.testAs1
  ],
  /* record */[
    /* alt */"testA_screenShot2",
    /* src */Images.testAs2
  ],
  /* record */[
    /* alt */"testA_screenShot3",
    /* src */Images.testAs3
  ],
  /* record */[
    /* alt */"testA_screenShot4",
    /* src */Images.testAs4
  ]
];

var testA = /* record */[
  /* description */"Note app which includes a news feed. Includes the ability to bookmark some articles for later.",
  /* name */"testA note app",
  testA_002,
  /* sourceCode */"",
  /* url */""
];

var projects_001 = /* :: */[
  w,
  /* :: */[
    testA,
    /* [] */0
  ]
];

var projects = /* :: */[
  init,
  projects_001
];

exports.init = init;
exports.w = w;
exports.testA = testA;
exports.projects = projects;
/* Images Not a pure module */
