// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var Blog = require("./pages/Blog.js");
var Home = require("./pages/Home.js");
var Misc = require("./pages/Misc.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Gallery = require("./pages/Gallery.js");
var Helpers = require("./data/Helpers.js");
var Navigation = require("./components/Navigation.js");
var ReactDOMRe = require("reason-react/src/ReactDOMRe.js");
var ScrollToTop = require("./components/ScrollToTop.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");

function App$App(Props) {
  var currentPage = Helpers.urlToPage(ReasonReactRouter.useUrl(undefined, /* () */0));
  var match = React.useReducer((function (state, action) {
          var scrollTop = action[1];
          switch (action[0]) {
            case 0 : 
                return /* record */[
                        /* scrollTop_Blog */scrollTop,
                        /* scrollTop_Gallery */state[/* scrollTop_Gallery */1],
                        /* scrollTop_Home */state[/* scrollTop_Home */2],
                        /* scrollTop_Misc */state[/* scrollTop_Misc */3]
                      ];
            case 1 : 
                return state;
            case 2 : 
                return /* record */[
                        /* scrollTop_Blog */state[/* scrollTop_Blog */0],
                        /* scrollTop_Gallery */scrollTop,
                        /* scrollTop_Home */state[/* scrollTop_Home */2],
                        /* scrollTop_Misc */state[/* scrollTop_Misc */3]
                      ];
            case 3 : 
                return /* record */[
                        /* scrollTop_Blog */state[/* scrollTop_Blog */0],
                        /* scrollTop_Gallery */state[/* scrollTop_Gallery */1],
                        /* scrollTop_Home */scrollTop,
                        /* scrollTop_Misc */state[/* scrollTop_Misc */3]
                      ];
            case 4 : 
                return /* record */[
                        /* scrollTop_Blog */state[/* scrollTop_Blog */0],
                        /* scrollTop_Gallery */state[/* scrollTop_Gallery */1],
                        /* scrollTop_Home */state[/* scrollTop_Home */2],
                        /* scrollTop_Misc */scrollTop
                      ];
            
          }
        }), /* record */[
        /* scrollTop_Blog */0,
        /* scrollTop_Gallery */0,
        /* scrollTop_Home */0,
        /* scrollTop_Misc */0
      ]);
  var dispatch = match[1];
  var state = match[0];
  var tmp;
  switch (currentPage) {
    case 0 : 
        tmp = React.createElement(Blog.make, {
              scroll: (function (scrollTop, param) {
                  return Curry._1(dispatch, /* SetScrollTop */[
                              /* Blog */0,
                              scrollTop
                            ]);
                }),
              scrollTop: state[/* scrollTop_Blog */0]
            });
        break;
    case 1 : 
        tmp = null;
        break;
    case 2 : 
        tmp = React.createElement(Gallery.make, {
              scroll: (function (scrollTop, param) {
                  return Curry._1(dispatch, /* SetScrollTop */[
                              /* Gallery */2,
                              scrollTop
                            ]);
                }),
              scrollTop: state[/* scrollTop_Gallery */1]
            });
        break;
    case 3 : 
        tmp = React.createElement(Home.make, {
              scroll: (function (scrollTop, param) {
                  return Curry._1(dispatch, /* SetScrollTop */[
                              /* Home */3,
                              scrollTop
                            ]);
                }),
              scrollTop: state[/* scrollTop_Home */2]
            });
        break;
    case 4 : 
        tmp = React.createElement(Misc.make, {
              scroll: (function (scrollTop, param) {
                  return Curry._1(dispatch, /* SetScrollTop */[
                              /* Miscellaneous */4,
                              scrollTop
                            ]);
                }),
              scrollTop: state[/* scrollTop_Misc */3]
            });
        break;
    
  }
  var tmp$1;
  switch (currentPage) {
    case 0 : 
        tmp$1 = state[/* scrollTop_Blog */0];
        break;
    case 1 : 
        tmp$1 = 0;
        break;
    case 2 : 
        tmp$1 = state[/* scrollTop_Gallery */1];
        break;
    case 3 : 
        tmp$1 = state[/* scrollTop_Home */2];
        break;
    case 4 : 
        tmp$1 = state[/* scrollTop_Misc */3];
        break;
    
  }
  var match$1 = tmp$1 > 600;
  return React.createElement("div", {
              id: "App",
              style: {
                background: "linear-gradient(to bottom right, #302a25, #3a3032, #3f3538)",
                bottom: "0",
                left: "0",
                margin: "0 auto",
                maxWidth: "850px",
                overflow: "hidden",
                position: "absolute",
                right: "0",
                top: "0"
              }
            }, React.createElement(Navigation.make, { }), tmp, React.createElement(ScrollToTop.make, {
                  currentPage: currentPage,
                  display: match$1 ? "block" : "none"
                }));
}

var App = /* module */[/* make */App$App];

ReactDOMRe.renderToElementWithId(React.createElement(App$App, { }), "AppContainer");

exports.App = App;
/*  Not a pure module */
