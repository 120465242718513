// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';


function pageToString(page) {
  switch (page) {
    case 0 : 
        return "blog";
    case 1 : 
        return "error";
    case 2 : 
        return "gallery";
    case 3 : 
        return "home";
    case 4 : 
        return "misc";
    
  }
}

function scrollToTop(_id) {
  return (
      document.getElementById(_id).scroll({
        top: 0,
        behavior: 'smooth'
      })
    );
}

function str(prim) {
  return prim;
}

function urlToPage(url) {
  var match = url[/* hash */1];
  switch (match) {
    case "blog" : 
        return /* Blog */0;
    case "gallery" : 
        return /* Gallery */2;
    case "" : 
    case "home" : 
        return /* Home */3;
    case "misc" : 
        return /* Miscellaneous */4;
    default:
      return /* Error */1;
  }
}

exports.pageToString = pageToString;
exports.scrollToTop = scrollToTop;
exports.str = str;
exports.urlToPage = urlToPage;
/* No side effect */
