// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var Box = require("./Box.js");
var React = require("react");
var Helpers = require("../data/Helpers.js");

function Header(Props) {
  var match = Props.background;
  var background = match !== undefined ? match : "linear-gradient(to bottom, rgba(70,60,63,0.9), rgba(60,50,53, 0.9))";
  var match$1 = Props.height;
  var height = match$1 !== undefined ? match$1 : "2.6rem";
  var pageName = Props.pageName;
  var match$2 = Props.position;
  var position = match$2 !== undefined ? match$2 : "absolute";
  return React.createElement("header", {
              id: "Header",
              style: {
                background: background,
                display: "flex",
                height: height,
                left: "0",
                padding: "0 5%",
                position: position,
                right: "0",
                top: "0",
                zIndex: "3",
                boxShadow: "rgb(38, 30, 29) 0px 0px 103px 20px",
                alignItems: "center",
                justifyContent: "space-between"
              }
            }, React.createElement(Box.make, {
                  fontFamily: "ork",
                  fontSize: "1.275rem",
                  letterSpacing: "-1px",
                  margin: "0",
                  children: null
                }, React.createElement("span", {
                      className: "yellowText"
                    }, Helpers.str("dP")), React.createElement("span", {
                      className: "redText"
                    }, Helpers.str("ns"))), React.createElement("div", {
                  style: {
                    fontSize: "1.35rem"
                  }
                }, Helpers.str(pageName)));
}

var make = Header;

exports.make = make;
/* Box Not a pure module */
