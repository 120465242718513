// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var Box = require("./Box.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Swipe = require("./Swipe.js");
var React = require("react");
var Button = require("./Button.js");
var Images = require("../data/Images.js");
var Helpers = require("../data/Helpers.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");

function ImgGallery(Props) {
  var currentImg = Props.currentImg;
  var currentImgIndex = Props.currentImgIndex;
  var hideComponent = Props.hideComponent;
  var imgList = Props.imgList;
  var showingComponent = Props.showingComponent;
  var match = React.useReducer((function (_state, action) {
          var direction = action[2];
          var index = action[1];
          var imgs = action[0];
          var tmp;
          if (direction !== 0) {
            switch (direction - 1 | 0) {
              case 0 : 
                  tmp = index + (imgs.length - 1 | 0) | 0;
                  break;
              case 1 : 
                  tmp = index + 1 | 0;
                  break;
              case 2 : 
                  tmp = 0;
                  break;
              
            }
          } else {
            tmp = 0;
          }
          var tmp$1;
          if (direction !== 0) {
            switch (direction - 1 | 0) {
              case 0 : 
                  tmp$1 = index + (imgs.length - 1 | 0) | 0;
                  break;
              case 1 : 
                  tmp$1 = index + 1 | 0;
                  break;
              case 2 : 
                  tmp$1 = 0;
                  break;
              
            }
          } else {
            tmp$1 = 0;
          }
          return /* record */[
                  /* currentImg */Caml_array.caml_array_get(imgs, Caml_int32.mod_(tmp, imgs.length)),
                  /* currentImgIndex */Caml_int32.mod_(tmp$1, imgs.length)
                ];
        }), /* record */[
        /* currentImg */currentImg,
        /* currentImgIndex */currentImgIndex
      ]);
  var dispatch = match[1];
  var state = match[0];
  return React.createElement(Swipe.make, {
              onSwipedLeft: (function (_event) {
                  return Curry._1(dispatch, /* ChangeImg */[
                              imgList,
                              state[/* currentImgIndex */1],
                              /* Right */2
                            ]);
                }),
              onSwipedRight: (function (_event) {
                  return Curry._1(dispatch, /* ChangeImg */[
                              imgList,
                              state[/* currentImgIndex */1],
                              /* Left */1
                            ]);
                }),
              children: React.createElement("div", {
                    className: "zoomedImage",
                    style: {
                      background: "rgba(40, 30, 30, 0.875)",
                      display: showingComponent ? "flex" : "none",
                      height: "100%",
                      left: "0",
                      margin: "0 auto",
                      position: "absolute",
                      right: "0",
                      top: "0",
                      zIndex: "5",
                      justifyContent: "center",
                      transition: "300ms"
                    }
                  }, React.createElement("p", {
                        style: {
                          position: "absolute",
                          top: "0"
                        }
                      }, Helpers.str(state[/* currentImg */0][/* alt */0])), React.createElement("img", {
                        style: {
                          height: "82%",
                          position: "absolute",
                          top: "3.4rem"
                        },
                        alt: state[/* currentImg */0][/* alt */0],
                        src: state[/* currentImg */0][/* src */1]
                      }), React.createElement(Box.make, {
                        bottom: "0",
                        maxWidth: "330px",
                        position: "absolute",
                        width: "70%",
                        children: null
                      }, React.createElement(Button.make, {
                            className: "button_PrevImg",
                            onClick: (function (param) {
                                return Curry._1(dispatch, /* ChangeImg */[
                                            imgList,
                                            state[/* currentImgIndex */1],
                                            /* Left */1
                                          ]);
                              }),
                            children: React.createElement("img", {
                                  style: {
                                    padding: "1rem",
                                    width: "2.0rem"
                                  },
                                  alt: "prev",
                                  src: Images.leftArrow
                                })
                          }), React.createElement(Button.make, {
                            className: "button_NextImg",
                            onClick: (function (param) {
                                return Curry._1(dispatch, /* ChangeImg */[
                                            imgList,
                                            state[/* currentImgIndex */1],
                                            /* Right */2
                                          ]);
                              }),
                            children: React.createElement("img", {
                                  style: {
                                    padding: "1rem",
                                    width: "2.0rem"
                                  },
                                  alt: "next",
                                  src: Images.rightArrow
                                })
                          }), React.createElement(Button.make, {
                            fontSize: "3rem",
                            onClick: hideComponent,
                            children: React.createElement("img", {
                                  style: {
                                    padding: "1rem",
                                    width: "2.0rem"
                                  },
                                  src: Images.chevronX
                                })
                          })))
            });
}

var make = ImgGallery;

exports.make = make;
/* Box Not a pure module */
