// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");

function getScrollTop(_id) {
  return (
    document.getElementById(_id).scrollTop
  );
}

function setScrollTop(_scrollTop, _id) {
  return (
      document.getElementById(_id).scroll({
        top: _scrollTop,
        behavior: 'smooth'
      })
    );
}

var Action = /* module */[
  /* getScrollTop */getScrollTop,
  /* setScrollTop */setScrollTop
];

function ScrollView(Props) {
  var match = Props.background;
  var background = match !== undefined ? match : "";
  var match$1 = Props.display;
  var display = match$1 !== undefined ? match$1 : "flex";
  var id = Props.id;
  var match$2 = Props.marginBottom;
  var marginBottom = match$2 !== undefined ? match$2 : "0rem";
  var match$3 = Props.position;
  var position = match$3 !== undefined ? match$3 : "absolute";
  var match$4 = Props.scroll;
  var scroll = match$4 !== undefined ? match$4 : (function (param, param$1) {
        return /* () */0;
      });
  var match$5 = Props.scrollTop;
  var scrollTop = match$5 !== undefined ? match$5 : 0;
  var match$6 = Props.transition;
  var transition = match$6 !== undefined ? match$6 : " 500ms cubic-bezier(0.08, 0.68, 0, 0.85), z-index 0s ";
  var match$7 = Props.zIndex;
  var zIndex = match$7 !== undefined ? match$7 : "1";
  var children = Props.children;
  var match$8 = React.useReducer((function (state, action) {
          if (action) {
            return /* record */[
                    /* scrollTop */action[0],
                    /* transform */state[/* transform */1]
                  ];
          } else {
            return /* record */[
                    /* scrollTop */state[/* scrollTop */0],
                    /* transform */"scale(1.0)"
                  ];
          }
        }), /* record */[
        /* scrollTop */scrollTop,
        /* transform */"scale(1.05)"
      ]);
  var dispatch = match$8[1];
  var state = match$8[0];
  React.useEffect((function () {
          setScrollTop(state[/* scrollTop */0], id);
          Curry._1(dispatch, /* Animate */0);
          return undefined;
        }), ([]));
  return React.createElement("div", {
              className: "scrollview scrollView",
              id: id,
              style: {
                background: background,
                bottom: "0",
                display: display,
                left: "0",
                marginBottom: marginBottom,
                overflowX: "hidden",
                overflowY: "scroll",
                paddingTop: "2.7rem",
                position: position,
                right: "0",
                top: "0",
                width: "100%",
                zIndex: zIndex,
                boxShadow: "inset rgb(16, 15, 34) 0px -150px 200px -200px",
                flexDirection: "column",
                transition: transition,
                transform: state[/* transform */1]
              },
              onScroll: (function (param) {
                  return Curry._2(scroll, getScrollTop(id), /* () */0);
                })
            }, children);
}

var make = ScrollView;

exports.Action = Action;
exports.make = make;
/* react Not a pure module */
