// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Swipe = require("./Swipe.js");
var React = require("react");
var Button = require("./Button.js");
var Images = require("../data/Images.js");
var Helpers = require("../data/Helpers.js");
var ReasonReactRouter = require("reason-react/src/ReasonReactRouter.js");

var menuItems = /* array */[
  /* record */[
    /* color */"lightpink",
    /* name */"home",
    /* page : Home */3
  ],
  /* record */[
    /* color */"#fee",
    /* name */"blog",
    /* page : Blog */0
  ],
  /* record */[
    /* color */"lightyellow",
    /* name */"gallery",
    /* page : Gallery */2
  ],
  /* record */[
    /* color */"#ffe0a0",
    /* name */"misc",
    /* page : Miscellaneous */4
  ]
];

function Navigation(Props) {
  var match = React.useReducer((function (state, action) {
          if (action.tag) {
            var match = action[0] === true;
            if (match) {
              return /* record */[
                      /* isMenuOpened */false,
                      /* openMenuButton */state[/* openMenuButton */1]
                    ];
            } else {
              return /* record */[
                      /* isMenuOpened */true,
                      /* openMenuButton */state[/* openMenuButton */1]
                    ];
            }
          } else {
            var match$1 = action[0] === /* Down */0;
            if (match$1) {
              return /* record */[
                      /* isMenuOpened */state[/* isMenuOpened */0],
                      /* openMenuButton */"translateY(6px)"
                    ];
            } else {
              return /* record */[
                      /* isMenuOpened */state[/* isMenuOpened */0],
                      /* openMenuButton */"translateY(-6px)"
                    ];
            }
          }
        }), /* record */[
        /* isMenuOpened */false,
        /* openMenuButton */"translateY(-6px)"
      ]);
  var dispatch = match[1];
  var state = match[0];
  React.useState((function () {
          setTimeout((function (param) {
                  return Curry._1(dispatch, /* Shift */Block.__(0, [/* Down */0]));
                }), 1000);
          setInterval((function (param) {
                  Curry._1(dispatch, /* Shift */Block.__(0, [/* Up */3]));
                  setTimeout((function (param) {
                          return Curry._1(dispatch, /* Shift */Block.__(0, [/* Down */0]));
                        }), 1100);
                  return /* () */0;
                }), 2250);
          return /* () */0;
        }));
  var isMenuOpened = state[/* isMenuOpened */0];
  return React.createElement("nav", {
              id: "Navigation",
              style: {
                display: "flex",
                width: "100%",
                justifyContent: "center"
              }
            }, React.createElement(Button.make, {
                  bottom: "0",
                  id: "Button_OpenMenu",
                  onClick: (function (param) {
                      return Curry._1(dispatch, /* ToggleMenu */Block.__(1, [false]));
                    }),
                  padding: "0",
                  position: "absolute",
                  transform: isMenuOpened ? "translateY(30%)" : "translateY(0%)",
                  transition: "transform 700ms cubic-bezier(0.08, 0.68, 0, 0.85)",
                  zIndex: "2",
                  children: React.createElement(Swipe.make, {
                        onSwipedUp: (function (_event) {
                            return Curry._1(dispatch, /* ToggleMenu */Block.__(1, [false]));
                          }),
                        children: React.createElement("img", {
                              style: {
                                background: "rgba(67, 56, 59, 0.50)",
                                width: "5rem",
                                borderRadius: "50px",
                                boxShadow: "0 0 60px 0 #483f40",
                                transition: "transform 1050ms ease-out",
                                transform: state[/* openMenuButton */1]
                              },
                              alt: "open_menu",
                              src: Images.chevronUp
                            })
                      })
                }), React.createElement("ul", {
                  id: "Container_Menu",
                  style: {
                    background: "linear-gradient(to top left, #332a2d, #3a3032, #4a4043)",
                    bottom: "0",
                    display: "flex",
                    fontFamily: "cagli",
                    height: "100vh",
                    listStyle: "none",
                    margin: "0",
                    padding: "0",
                    position: "absolute",
                    textAlign: "center",
                    width: "100%",
                    zIndex: isMenuOpened ? "3" : "1",
                    opacity: isMenuOpened ? "0.9" : "0",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    transition: "450ms cubic-bezier(0.08, 0.68, 0, 0.85)",
                    transform: isMenuOpened ? "translateY(0px)" : "translateY(10px)",
                    pointerEvents: isMenuOpened ? "auto" : "none"
                  }
                }, React.createElement(Swipe.make, {
                      onSwipedDown: (function (_event) {
                          return Curry._1(dispatch, /* ToggleMenu */Block.__(1, [true]));
                        }),
                      children: null
                    }, $$Array.map((function (menuItem) {
                            return React.createElement("li", {
                                        key: menuItem[/* name */1]
                                      }, React.createElement(Button.make, {
                                            background: "none",
                                            className: "menuItem",
                                            color: menuItem[/* color */0],
                                            fontSize: "3.75rem",
                                            onClick: (function (param) {
                                                if (isMenuOpened) {
                                                  Curry._1(dispatch, /* ToggleMenu */Block.__(1, [true]));
                                                  return ReasonReactRouter.push("#" + menuItem[/* name */1]);
                                                } else {
                                                  return /* () */0;
                                                }
                                              }),
                                            pointerEvents: isMenuOpened ? "auto" : "none",
                                            tabIndex: isMenuOpened ? 0 : -1,
                                            width: "100%",
                                            children: Helpers.str(menuItem[/* name */1])
                                          }));
                          }), menuItems), React.createElement("li", {
                          style: {
                            display: "flex",
                            justifyContent: "center"
                          }
                        }, React.createElement(Button.make, {
                              id: "Button_CloseMenu",
                              onClick: (function (param) {
                                  if (isMenuOpened) {
                                    return Curry._1(dispatch, /* ToggleMenu */Block.__(1, [true]));
                                  } else {
                                    return /* () */0;
                                  }
                                }),
                              pointerEvents: isMenuOpened ? "auto" : "none",
                              tabIndex: isMenuOpened ? 0 : -1,
                              children: React.createElement("img", {
                                    style: {
                                      padding: "0.5rem 2rem 2rem",
                                      width: "4.25rem",
                                      pointerEvents: "none"
                                    },
                                    alt: "close_menu",
                                    src: Images.chevronX
                                  })
                            })))));
}

var make = Navigation;

exports.menuItems = menuItems;
exports.make = make;
/* Swipe Not a pure module */
