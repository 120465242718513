// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Button = require("./Button.js");
var Helpers = require("../data/Helpers.js");

function ScrollToTop(Props) {
  var currentPage = Props.currentPage;
  var match = Props.display;
  var display = match !== undefined ? match : "none";
  return React.createElement(Button.make, {
              background: "#433",
              border: "dashed #ffe0a0",
              borderRadius: "5%",
              borderWidth: "3px",
              bottom: "6rem",
              boxShadow: "rgb(42, 34, 35) 0px 0px 10px 5px",
              color: "#eee",
              display: display,
              fontSize: "1.3rem",
              id: "Button_ScrollToTop",
              onClick: (function (param) {
                  var tmp;
                  switch (currentPage) {
                    case 0 : 
                        tmp = "ScrollView_Blog";
                        break;
                    case 1 : 
                        tmp = "ScrollView_Error";
                        break;
                    case 2 : 
                        tmp = "ScrollView_Gallery";
                        break;
                    case 3 : 
                        tmp = "ScrollView_Home";
                        break;
                    case 4 : 
                        tmp = "ScrollView_misc";
                        break;
                    
                  }
                  return Helpers.scrollToTop(tmp);
                }),
              padding: "0.8rem",
              position: "absolute",
              right: "5%",
              zIndex: "1",
              children: "top"
            });
}

var make = ScrollToTop;

exports.make = make;
/* react Not a pure module */
