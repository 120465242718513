// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var Box = require("./Box.js");
var React = require("react");
var Images = require("../data/Images.js");
var Helpers = require("../data/Helpers.js");

function Footer(Props) {
  var match = Props.background;
  var background = match !== undefined ? match : "linear-gradient(to bottom, rgb(42, 33, 30), rgb(35, 28, 28), rgb(30, 20, 22))";
  var match$1 = Props.boxShadow;
  var boxShadow = match$1 !== undefined ? match$1 : "rgb(25, 20, 15) 0px 0px 103px 10px";
  var match$2 = Props.display;
  var display = match$2 !== undefined ? match$2 : "flex";
  var match$3 = Props.height;
  var height = match$3 !== undefined ? match$3 : "";
  var match$4 = Props.flexDirection;
  var flexDirection = match$4 !== undefined ? match$4 : "column";
  var match$5 = Props.fontFamily;
  var fontFamily = match$5 !== undefined ? match$5 : "";
  var match$6 = Props.padding;
  var padding = match$6 !== undefined ? match$6 : "1.5rem 6% 7rem";
  var match$7 = Props.position;
  var position = match$7 !== undefined ? match$7 : "relative";
  return React.createElement("footer", {
              id: "Footer",
              style: {
                background: background,
                bottom: "0",
                display: display,
                fontFamily: fontFamily,
                height: height,
                left: "0",
                padding: padding,
                position: position,
                right: "0",
                zIndex: "1",
                boxShadow: boxShadow,
                alignItems: "center",
                flexDirection: flexDirection,
                transition: "0s"
              }
            }, React.createElement("p", {
                  style: {
                    color: "#ffe0a0",
                    padding: "0 1rem",
                    textAlign: "center"
                  }
                }, Helpers.str("Where else you can find me")), React.createElement(Box.make, {
                  className: "socialMediaIcons",
                  children: null
                }, React.createElement("a", {
                      className: "keybase",
                      href: "https://keybase.io/d2clabs",
                      target: "_blank"
                    }, React.createElement("img", {
                          style: {
                            padding: "1.5rem 1.5rem 1.7rem",
                            width: "1.8rem"
                          },
                          alt: "keybase",
                          src: Images.keybaseIcon
                        })), React.createElement("a", {
                      href: "https://mastodon.social/@dpeyou",
                      target: "_blank"
                    }, React.createElement("img", {
                          style: {
                            padding: "1.5rem"
                          },
                          alt: "mastodon",
                          src: Images.mastodonIcon
                        })), React.createElement("a", {
                      className: "mastodon",
                      href: "https://gitlab.com/dpeyou",
                      target: "_blank"
                    }, React.createElement("img", {
                          style: {
                            padding: "1.5rem"
                          },
                          alt: "gitlab",
                          src: Images.gitlabIcon
                        }))), React.createElement("p", {
                  style: {
                    padding: "0 1rem",
                    textAlign: "center"
                  }
                }, Helpers.str("Site crafted from my Ubuntu Linux, somewhere in this world")), React.createElement("a", {
                  className: "gitlab",
                  style: {
                    background: "linear-gradient(to top, rgb(70, 60, 63), rgb(80, 70, 63))",
                    color: "#dfd",
                    margin: "2rem 0",
                    padding: "1rem",
                    textDecoration: "none",
                    borderRadius: "1px"
                  },
                  href: "https://gitlab.com/dpeyou/dpeyou",
                  target: "_blank"
                }, Helpers.str("View this website's source code")), React.createElement("a", {
                  style: {
                    margin: "3.25rem 0"
                  },
                  href: "https://en.wikipedia.org/wiki/Cameroon",
                  target: "_blank"
                }, React.createElement("img", {
                      style: {
                        padding: "1.5rem"
                      },
                      alt: "flag_of_cameroon",
                      src: Images.cameroon
                    })), React.createElement("p", undefined, Helpers.str("© " + "2019 D2C Labs")));
}

var make = Footer;

exports.make = make;
/* Box Not a pure module */
