// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var Box = require("./Box.js");
var React = require("react");
var Button = require("./Button.js");
var Helpers = require("../data/Helpers.js");

function Post(Props) {
  var clickPost = Props.clickPost;
  var content = Props.content;
  var match = Props.date;
  var date = match !== undefined ? match : "";
  var id = Props.id;
  var match$1 = Props.lastUpdate;
  var lastUpdate = match$1 !== undefined ? match$1 : "";
  var title = Props.title;
  return React.createElement("article", {
              className: "post",
              id: id,
              style: {
                display: "flex",
                marginBottom: "2.5rem",
                flexDirection: "column",
                transition: "300ms"
              }
            }, React.createElement("h1", {
                  className: "postTitle",
                  style: {
                    color: "#ffe0a0",
                    margin: "0"
                  }
                }, Helpers.str(title)), React.createElement(Box.make, {
                  alignItems: "flex-start",
                  color: "lightpink",
                  flexDirection: "column",
                  margin: "0 1rem",
                  children: React.createElement("p", {
                        style: {
                          marginTop: "0.5rem"
                        }
                      }, Helpers.str("Posted: " + date), React.createElement("br", undefined), Helpers.str("Last update: " + lastUpdate))
                }), React.createElement("p", {
                  className: "postContent",
                  style: {
                    borderTop: "dashed 3px #ffe0a0",
                    height: "6rem",
                    lineHeight: "1.65",
                    margin: "0",
                    overflow: "hidden",
                    paddingTop: "1rem"
                  }
                }, content), React.createElement(Button.make, {
                  background: "linear-gradient(to top, rgb(70, 60, 63), rgb(80, 70, 63))",
                  className: "button_OpenPost",
                  color: "#fee",
                  margin: "0.75rem 0",
                  onClick: clickPost,
                  padding: "0.75rem 0.5rem",
                  children: Helpers.str("Read post")
                }));
}

var make = Post;

exports.make = make;
/* Box Not a pure module */
