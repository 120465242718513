// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var Box = require("../components/Box.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Footer = require("../components/Footer.js");
var Header = require("../components/Header.js");
var Helpers = require("../data/Helpers.js");
var Project = require("../components/Project.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Projects = require("../data/Projects.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var ImgGallery = require("../components/ImgGallery.js");
var ScrollView = require("../components/ScrollView.js");

var defaultScreenShot = /* record */[
  /* alt */"",
  /* src */""
];

function Gallery(Props) {
  var scroll = Props.scroll;
  var scrollTop = Props.scrollTop;
  var match = React.useReducer((function (state, action) {
          if (action.tag) {
            var match = action[0] === true;
            if (match) {
              return /* record */[
                      /* currentImg */defaultScreenShot,
                      /* currentImgIndex */state[/* currentImgIndex */1],
                      /* imgList : array */[],
                      /* showComponent */false
                    ];
            } else {
              return /* record */[
                      /* currentImg */state[/* currentImg */0],
                      /* currentImgIndex */state[/* currentImgIndex */1],
                      /* imgList */state[/* imgList */2],
                      /* showComponent */true
                    ];
            }
          } else {
            var index = action[1];
            var imgs = action[0];
            return /* record */[
                    /* currentImg */Caml_array.caml_array_get(imgs, index),
                    /* currentImgIndex */index,
                    /* imgList */imgs,
                    /* showComponent */true
                  ];
          }
        }), /* record */[
        /* currentImg */defaultScreenShot,
        /* currentImgIndex */0,
        /* imgList : array */[],
        /* showComponent */false
      ]);
  var dispatch = match[1];
  var state = match[0];
  var currentImg = state[/* currentImg */0];
  var match$1 = Caml_obj.caml_equal(currentImg, defaultScreenShot);
  return React.createElement("div", {
              id: "Page_Gallery"
            }, React.createElement(Header.make, {
                  pageName: "gallery"
                }), match$1 ? null : React.createElement(ImgGallery.make, {
                    currentImg: state[/* currentImg */0],
                    currentImgIndex: state[/* currentImgIndex */1],
                    hideComponent: (function (param) {
                        return Curry._1(dispatch, /* ToggleComponent */Block.__(1, [true]));
                      }),
                    imgList: state[/* imgList */2],
                    showingComponent: state[/* showComponent */3]
                  }), React.createElement(ScrollView.make, {
                  id: "ScrollView_Gallery",
                  scroll: scroll,
                  scrollTop: scrollTop,
                  children: null
                }, React.createElement("main", {
                      style: {
                        margin: "0 0 10rem",
                        padding: "0 6%"
                      }
                    }, React.createElement(Box.make, {
                          flexDirection: "column",
                          margin: "4rem 0",
                          children: null
                        }, React.createElement("h1", {
                              style: {
                                color: "#ffe0a0",
                                fontSize: "1.7rem",
                                margin: "0.8rem",
                                textAlign: "center"
                              }
                            }, Helpers.str("Projects")), React.createElement("h3", {
                              style: {
                                color: "lightpink",
                                fontSize: "1.2rem",
                                margin: "0",
                                textAlign: "center"
                              }
                            }, Helpers.str("What I'm working on..."))), React.createElement("p", undefined, Helpers.str("I have also made the source code for this website available on my Gitlab profile & the button for that is in the footer. Forgive me, as I have not yet added proper syntax-highlighting. Don't worry, it's coming.")), React.createElement("p", undefined, Helpers.str("Most of my projects are deployed either via: ")), React.createElement(Box.make, {
                          alignItems: "flex-start",
                          flexDirection: "column",
                          margin: "1.5rem 0",
                          children: null
                        }, React.createElement("a", {
                              className: "button_Zeit",
                              style: {
                                background: "linear-gradient(to top, rgb(70, 60, 63), rgb(80, 70, 63))",
                                color: "#fee",
                                margin: "0.5rem",
                                padding: "0.8rem",
                                textDecoration: "none",
                                borderRadius: "1px"
                              },
                              href: "https://zeit.co/",
                              target: "_blank"
                            }, Helpers.str("Zeit HQ")), React.createElement("a", {
                              className: "button_Heroku",
                              style: {
                                background: "linear-gradient(to top, rgb(70, 60, 63), rgb(80, 70, 63))",
                                color: "#Fee",
                                margin: "0.5rem",
                                padding: "0.8rem",
                                textDecoration: "none",
                                borderRadius: "1px"
                              },
                              href: "https://www.heroku.com/",
                              target: "_blank"
                            }, Helpers.str("Heroku"))), React.createElement(Box.make, {
                          alignItems: "flex-start",
                          flexDirection: "column",
                          children: null
                        }, React.createElement("h3", {
                              className: "yellowText",
                              style: {
                                lineHeight: "1.65",
                                margin: "0"
                              }
                            }, Helpers.str("Most of these projects were made in a similar fashion:")), React.createElement("ul", {
                              style: {
                                lineHeight: "1.65",
                                marginBottom: "4rem"
                              }
                            }, React.createElement("li", undefined, Helpers.str("Written in ReasonML compiled to JavaScript. The Reason-React library (ReasonML bindings for ReactJS) will most likely be prominently featured.")), React.createElement("li", undefined, Helpers.str("Presence of GraphQL through the URQL framework. Unfortunately Apollo-Client had a breaking change that I couldn't get around, so I migrated some apps to URQL.")), React.createElement("li", undefined, Helpers.str("Bundled with ParcelJS over Webpack.")))), $$Array.map((function (project) {
                            return React.createElement(Project.make, {
                                        description: project[/* description */0],
                                        onImgClick: (function (imgs, index, _event) {
                                            return Curry._1(dispatch, /* LoadImgs */Block.__(0, [
                                                          imgs,
                                                          index
                                                        ]));
                                          }),
                                        name: project[/* name */1],
                                        screenShots: project[/* screenShots */2],
                                        sourceCode: project[/* sourceCode */3],
                                        url: project[/* url */4],
                                        key: project[/* name */1]
                                      });
                          }), $$Array.of_list(Projects.projects))), React.createElement(Footer.make, { })));
}

var make = Gallery;

exports.defaultScreenShot = defaultScreenShot;
exports.make = make;
/* Box Not a pure module */
