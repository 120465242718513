// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Images = require("./Images.js");
var Helpers = require("./Helpers.js");

var introPost_000 = /* content */React.createElement(React.Fragment, undefined, Helpers.str("Hello World! Finally getting this blog thingy started. For me the hardest part about working on many apps is figuring out a good stopping point. At the risk of sounding repetetive, Im going to say it again: learning never stops! That means each time I learn something new, I feel silly about the way I was previously coding and frantically try to update everything. The process is always exciting... and lame af lol. Welcome to my blog y'all. I might also post in French and Portuguese. Spanish will require a little more time for me and Japansese... LOL! Another 2 years as I get busy. Here's a shot of me working on my site..."), React.createElement("img", {
          style: {
            margin: "1rem 0",
            width: "100%"
          },
          src: Images.post1shot1
        }));

var introPost = /* record */[
  introPost_000,
  /* date */"11th Nov '19",
  /* id */"post-1",
  /* lastUpdate */"12th Nov '19",
  /* title */"Intro post"
];

var posts = /* :: */[
  introPost,
  /* [] */0
];

exports.introPost = introPost;
exports.posts = posts;
/* introPost Not a pure module */
