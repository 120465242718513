// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var Box = require("../components/Box.js");
var React = require("react");
var Footer = require("../components/Footer.js");
var Header = require("../components/Header.js");
var Helpers = require("../data/Helpers.js");
var ScrollView = require("../components/ScrollView.js");

function Home(Props) {
  var scroll = Props.scroll;
  var scrollTop = Props.scrollTop;
  return React.createElement("div", {
              id: "Page_Home"
            }, React.createElement(Header.make, {
                  pageName: "home"
                }), React.createElement(ScrollView.make, {
                  id: "ScrollView_Home",
                  scroll: scroll,
                  scrollTop: scrollTop,
                  children: null
                }, React.createElement("main", {
                      style: {
                        margin: "0 0 10rem",
                        padding: "0 5%"
                      }
                    }, React.createElement(Box.make, {
                          flexDirection: "column",
                          margin: "4rem 0",
                          children: null
                        }, React.createElement("h1", {
                              className: "yellowText",
                              style: {
                                fontSize: "1.7rem",
                                marginBottom: "0.8rem"
                              }
                            }, Helpers.str("Darren Peyou Ndi-Samba")), React.createElement(Box.make, {
                              fontSize: "1.2rem",
                              margin: "0",
                              whiteSpace: "pre",
                              children: null
                            }, React.createElement("span", {
                                  className: "lightPinkText"
                                }, Helpers.str("Web Developer")), React.createElement("span", {
                                  className: "separator"
                                }, Helpers.str("  |  ")), React.createElement("span", {
                                  className: "greenText"
                                }, Helpers.str("Avocado lover")))), React.createElement("p", {
                          className: "shortBio"
                        }, Helpers.str("I'm a web developer who enjoys creating user-interfaces. The NodeJs ecosystem rocks & I forgot what life was like without ReactJs. My strength is on the front-end, but I enjoy every aspect of web development.")), React.createElement("p", {
                          className: "shortBio"
                        }, Helpers.str("I learned how to code using services like TeamTreehouse & FreeCodeCamp, by following random tutorials online (dev.to, tutorialspoint) and reading documentation until my eyes would close against my will.")), React.createElement("h2", {
                          className: "yellowText",
                          style: {
                            fontSize: "1.5rem"
                          }
                        }, Helpers.str("My developer stack includes:")), React.createElement("ul", {
                          style: {
                            lineHeight: "1.6"
                          }
                        }, React.createElement("li", {
                              className: "yellowText"
                            }, Helpers.str("HTML & CSS")), React.createElement("li", {
                              className: "lightGreenText"
                            }, Helpers.str("NodeJS")), React.createElement("li", {
                              className: "redText"
                            }, Helpers.str("ReasonML")), React.createElement("li", {
                              className: "lightBlueText"
                            }, Helpers.str("ReactJS & React-Native")), React.createElement("li", {
                              className: "pinkText"
                            }, Helpers.str("GraphQL"))), React.createElement("p", undefined, Helpers.str("Other pieces of technology I wish to experiment with include VueJS, Rust, Scala & databases in general.")), React.createElement("h2", {
                          className: "yellowText",
                          style: {
                            fontSize: "1.5rem"
                          }
                        }, Helpers.str("I'm a polyglot, language nerd")), React.createElement("ol", {
                          style: {
                            fontSize: "1rem",
                            lineHeight: "1.6",
                            listStyle: "square"
                          }
                        }, React.createElement("li", {
                              lang: "fr-fr"
                            }, Helpers.str("Je parle français")), React.createElement("li", {
                              lang: "pt-br"
                            }, Helpers.str("Falo português básico")), React.createElement("li", {
                              lang: "es-mx"
                            }, Helpers.str("Hablo un poco de español")), React.createElement("li", {
                              lang: "ja-jp"
                            }, Helpers.str("日本語勉強しでいます (very basic Japanese)"))), React.createElement("p", undefined, Helpers.str("Others I'm currently learning (or will soon start) include German, Russian & my African language: Ewondo. Once I can speak Ewondo beyond a couple of phrases, I'll be granted entrance into Wakanda.")), React.createElement("h3", {
                          lang: "fr-fr",
                          style: {
                            margin: "0 1rem 0 0",
                            textAlign: "right"
                          }
                        }, React.createElement("span", {
                              className: "redText"
                            }, Helpers.str("Fra")), React.createElement("span", {
                              className: "whiteText"
                            }, Helpers.str("nç")), React.createElement("span", {
                              className: "blueText"
                            }, Helpers.str("ais"))), React.createElement("p", {
                          lang: "fr-fr",
                          style: {
                            marginTop: "0",
                            padding: "0.5rem 0.8rem",
                            borderRadius: "5px",
                            boxShadow: "rgb(100, 85, 80) 0 0 120px -20px"
                          }
                        }, Helpers.str("J\'ai vecu au Cameroun pour dix ans et c\'est de là que mon français vient. C\'est mon pays d\'origine et j\'adore voyager.")), React.createElement("h3", {
                          lang: "pt-br",
                          style: {
                            fontWeight: "400",
                            margin: "0 1rem 0 0",
                            textAlign: "right"
                          }
                        }, React.createElement("span", {
                              className: "greenText"
                            }, Helpers.str("Po")), React.createElement("span", {
                              className: "yellowText"
                            }, Helpers.str("rt")), React.createElement("span", {
                              className: "blueText"
                            }, Helpers.str("u")), React.createElement("span", {
                              className: "yellowText"
                            }, Helpers.str("gu")), React.createElement("span", {
                              className: "greenText"
                            }, Helpers.str("ês"))), React.createElement("p", {
                          lang: "pt-br",
                          style: {
                            marginTop: "0",
                            padding: "0.5rem 0.8rem",
                            borderRadius: "5px",
                            boxShadow: "rgb(100, 85, 80) 0 0 120px -20px"
                          }
                        }, Helpers.str("Comecei a aprender o português em outubro de 2017. Estava escutando a música todos os dias e o app chamado "), React.createElement("a", {
                              className: "lightGreenText",
                              href: "https://www.duolingo.com",
                              target: "_blank"
                            }, Helpers.str("Duolingo")), Helpers.str(" me ajudou muito também. Ainda não estou completamente acostumado com o jeito de falar dos brasileiros (e doutros lusofones)."))), React.createElement(Footer.make, { })));
}

var make = Home;

exports.make = make;
/* Box Not a pure module */
