// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");

function Button(Props) {
  var match = Props.alignItems;
  var alignItems = match !== undefined ? match : "center";
  var match$1 = Props.onClickAnimation;
  var onClickAnimation = match$1 !== undefined ? match$1 : true;
  var match$2 = Props.background;
  var background = match$2 !== undefined ? match$2 : "none";
  var match$3 = Props.border;
  var border = match$3 !== undefined ? match$3 : "none";
  var match$4 = Props.borderRadius;
  var borderRadius = match$4 !== undefined ? match$4 : "4px";
  var match$5 = Props.borderWidth;
  var borderWidth = match$5 !== undefined ? match$5 : "";
  var match$6 = Props.bottom;
  var bottom = match$6 !== undefined ? match$6 : "initial";
  var match$7 = Props.boxShadow;
  var boxShadow = match$7 !== undefined ? match$7 : "";
  var match$8 = Props.className;
  var className = match$8 !== undefined ? match$8 : "";
  var match$9 = Props.color;
  var color = match$9 !== undefined ? match$9 : "#eee";
  var match$10 = Props.display;
  var display = match$10 !== undefined ? match$10 : "flex";
  var match$11 = Props.flex;
  var flex = match$11 !== undefined ? match$11 : "initial";
  var match$12 = Props.flexDirection;
  var flexDirection = match$12 !== undefined ? match$12 : "";
  var match$13 = Props.fontSize;
  var fontSize = match$13 !== undefined ? match$13 : "1.05rem";
  var match$14 = Props.height;
  var height = match$14 !== undefined ? match$14 : "initial";
  var match$15 = Props.id;
  var id = match$15 !== undefined ? match$15 : "";
  var match$16 = Props.justifyContent;
  var justifyContent = match$16 !== undefined ? match$16 : "center";
  var match$17 = Props.left;
  var left = match$17 !== undefined ? match$17 : "";
  var match$18 = Props.margin;
  var margin = match$18 !== undefined ? match$18 : "0";
  var match$19 = Props.onClick;
  var onClick = match$19 !== undefined ? match$19 : (function (param) {
        return /* () */0;
      });
  var match$20 = Props.opacity;
  var opacity = match$20 !== undefined ? match$20 : "1";
  var match$21 = Props.onTouchMove;
  var onTouchMove = match$21 !== undefined ? match$21 : (function (_touchEvent) {
        return /* () */0;
      });
  var match$22 = Props.padding;
  var padding = match$22 !== undefined ? match$22 : "0";
  var match$23 = Props.pointerEvents;
  var pointerEvents = match$23 !== undefined ? match$23 : "auto";
  var match$24 = Props.position;
  var position = match$24 !== undefined ? match$24 : "initial";
  var match$25 = Props.right;
  var right = match$25 !== undefined ? match$25 : "";
  var match$26 = Props.tabIndex;
  var tabIndex = match$26 !== undefined ? match$26 : 0;
  var match$27 = Props.textAlign;
  var textAlign = match$27 !== undefined ? match$27 : "";
  var match$28 = Props.textDecoration;
  var textDecoration = match$28 !== undefined ? match$28 : "";
  var match$29 = Props.transform;
  var transform = match$29 !== undefined ? match$29 : "";
  var match$30 = Props.transition;
  var transition = match$30 !== undefined ? match$30 : "125ms cubic-bezier(0.08, 0.68, 0, 0.85)";
  var match$31 = Props.width;
  var width = match$31 !== undefined ? match$31 : "initial";
  var match$32 = Props.zIndex;
  var zIndex = match$32 !== undefined ? match$32 : "initial";
  var children = Props.children;
  var match$33 = React.useReducer((function (_state, action) {
          if (action) {
            return /* record */[
                    /* isGrowing */false,
                    /* transform */"scale(1.0)"
                  ];
          } else {
            return /* record */[
                    /* isGrowing */true,
                    /* transform */"scale(1.15)"
                  ];
          }
        }), /* record */[
        /* isGrowing */false,
        /* transform */"scale(1.0)"
      ]);
  var dispatch = match$33[1];
  var state = match$33[0];
  if (state[/* isGrowing */0] === true) {
    setTimeout((function (param) {
            return Curry._1(dispatch, /* Shrink */1);
          }), 125);
  }
  var match$34 = transform === "";
  return React.createElement("button", {
              className: className,
              id: id,
              style: {
                background: background,
                border: border,
                borderWidth: borderWidth,
                bottom: bottom,
                color: color,
                display: display,
                fontSize: fontSize,
                height: height,
                left: left,
                margin: margin,
                padding: padding,
                position: position,
                right: right,
                textAlign: textAlign,
                textDecoration: textDecoration,
                width: width,
                zIndex: zIndex,
                opacity: opacity,
                borderRadius: borderRadius,
                boxShadow: boxShadow,
                alignItems: alignItems,
                flex: flex,
                flexDirection: flexDirection,
                justifyContent: justifyContent,
                transition: transition,
                transform: match$34 ? state[/* transform */1] : transform,
                pointerEvents: pointerEvents
              },
              tabIndex: tabIndex,
              type: "button",
              onClick: (function (_mouseClick) {
                  var match = onClickAnimation === true;
                  if (match) {
                    return Curry._1(onClick, Curry._1(dispatch, /* Grow */0));
                  } else {
                    return Curry._1(onClick, /* () */0);
                  }
                }),
              onTouchMove: onTouchMove
            }, children);
}

var make = Button;

exports.make = make;
/* react Not a pure module */
