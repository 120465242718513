// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var Box = require("./Box.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Images = require("../data/Images.js");
var Helpers = require("../data/Helpers.js");

function Project(Props) {
  var description = Props.description;
  var onImgClick = Props.onImgClick;
  var name = Props.name;
  var screenShots = Props.screenShots;
  var sourceCode = Props.sourceCode;
  var url = Props.url;
  var match = sourceCode === "";
  var match$1 = url === "";
  return React.createElement("article", {
              className: "project",
              style: {
                borderTop: "2px solid #ffe0a0",
                marginBottom: "5rem",
                paddingTop: "0.3rem",
                boxShadow: "#211 0px -100px 100px -100px"
              }
            }, React.createElement("h1", {
                  className: "projectTitle yellowText",
                  style: {
                    marginBottom: "0.5rem"
                  }
                }, Helpers.str(name)), React.createElement("p", {
                  className: "projectContent",
                  style: {
                    marginTop: "0.5rem"
                  }
                }, Helpers.str(description)), React.createElement(Box.make, {
                  border: "dashed #ffe0a0",
                  borderWidth: "2px 0 0",
                  boxShadow: "rgb(28, 18, 15) 0px 0px 100px -30px",
                  className: "slideShow",
                  justifyContent: "flex-start",
                  margin: "1.5rem 0 1.5rem",
                  overflowX: "scroll",
                  children: $$Array.mapi((function (index, image) {
                          return React.createElement("img", {
                                      key: image[/* alt */0],
                                      style: {
                                        border: "solid #322",
                                        borderWidth: "0 1px",
                                        height: "8rem"
                                      },
                                      alt: image[/* alt */0],
                                      src: image[/* src */1],
                                      onClick: Curry._2(onImgClick, screenShots, index)
                                    });
                        }), screenShots)
                }), React.createElement(Box.make, {
                  className: "links",
                  justifyContent: "space-between",
                  children: null
                }, match ? React.createElement("p", {
                        className: "greenText",
                        style: {
                          width: "50%"
                        }
                      }, Helpers.str("Code not available yet")) : React.createElement("a", {
                        className: "sourceCode link",
                        style: {
                          background: "linear-gradient(to top, rgb(70, 60, 63), rgb(80, 70, 63))",
                          color: "#fee",
                          display: "flex",
                          fontSize: "1.25rem",
                          padding: "0.8rem",
                          textDecoration: "none",
                          whiteSpace: "pre",
                          borderRadius: "1px"
                        },
                        href: sourceCode,
                        target: "_blank"
                      }, Helpers.str("Code "), React.createElement("img", {
                            style: {
                              width: "1.0rem"
                            },
                            alt: "gitlab_icon",
                            src: Images.gitlabIcon
                          })), match$1 ? React.createElement("p", {
                        style: {
                          color: "#fca326",
                          textAlign: "right",
                          width: "50%"
                        }
                      }, Helpers.str("Request for access to demo")) : React.createElement("a", {
                        className: "demo link",
                        style: {
                          background: "linear-gradient(to top, rgb(70, 60, 63), rgb(80, 70, 63))",
                          color: "#fee",
                          display: "flex",
                          fontSize: "1.25rem",
                          padding: "0.8rem",
                          textDecoration: "none",
                          whiteSpace: "pre",
                          borderRadius: "1px"
                        },
                        href: url,
                        target: "_blank"
                      }, Helpers.str("Demo "), React.createElement("img", {
                            style: {
                              width: "1.15rem"
                            },
                            alt: "external_link",
                            src: Images.extLink
                          }))));
}

var make = Project;

exports.make = make;
/* Box Not a pure module */
