// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var Box = require("./Box.js");
var React = require("react");
var Button = require("./Button.js");
var Header = require("./Header.js");
var Images = require("../data/Images.js");
var Helpers = require("../data/Helpers.js");
var ScrollView = require("./ScrollView.js");

function Reader(Props) {
  var closePost = Props.closePost;
  var currentPost = Props.currentPost;
  return React.createElement("div", {
              id: "Reader"
            }, React.createElement(Header.make, {
                  pageName: "blog: " + currentPost[/* title */4]
                }), React.createElement(ScrollView.make, {
                  background: "linear-gradient(to bottom, rgb(46, 37, 40), rgb(53, 43, 45), rgb(69, 59, 62))",
                  id: "ScrollView_Reader",
                  children: React.createElement("main", {
                        style: {
                          lineHeight: "1.65",
                          padding: "0 6% 10rem"
                        }
                      }, React.createElement(Box.make, {
                            color: "lightpink",
                            flexDirection: "column",
                            margin: "4.8rem 0",
                            children: null
                          }, React.createElement("h1", {
                                className: "postTitle",
                                style: {
                                  color: "#ffe0a0",
                                  margin: "0",
                                  textAlign: "center"
                                }
                              }, Helpers.str(currentPost[/* title */4])), React.createElement("div", undefined, Helpers.str("Posted: " + currentPost[/* date */1])), React.createElement("div", undefined, Helpers.str("Last update: " + currentPost[/* lastUpdate */3]))), React.createElement("p", {
                            style: {
                              border: "dashed #ffe0a0",
                              borderWidth: "3px 0 0",
                              padding: "1rem 0 0"
                            }
                          }, currentPost[/* content */0]))
                }), React.createElement(Button.make, {
                  background: "#433",
                  border: "dashed #ffe0a0",
                  borderRadius: "5%",
                  borderWidth: "3px",
                  bottom: "6rem",
                  boxShadow: "rgb(42, 34, 35) 0px 0px 10px 5px",
                  className: "button_ClosePost",
                  left: "2rem",
                  onClick: closePost,
                  padding: "0.5rem",
                  position: "absolute",
                  zIndex: "2",
                  children: React.createElement("img", {
                        style: {
                          width: "2.2rem"
                        },
                        src: Images.chevronX
                      })
                }));
}

var make = Reader;

exports.make = make;
/* Box Not a pure module */
