// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var Post = require("../components/Post.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Posts = require("../data/Posts.js");
var React = require("react");
var Footer = require("../components/Footer.js");
var Header = require("../components/Header.js");
var Reader = require("../components/Reader.js");
var ScrollView = require("../components/ScrollView.js");

function Blog(Props) {
  var scroll = Props.scroll;
  var scrollTop = Props.scrollTop;
  var match = React.useReducer((function (_state, action) {
          return /* record */[
                  /* currentPost */action[0],
                  /* isPostOpened */!action[1]
                ];
        }), /* record */[
        /* currentPost */Posts.introPost,
        /* isPostOpened */false
      ]);
  var dispatch = match[1];
  var state = match[0];
  var currentPost = state[/* currentPost */0];
  var isPostOpened = state[/* isPostOpened */1];
  return React.createElement("div", {
              id: "Page_Blog"
            }, isPostOpened ? React.createElement(Reader.make, {
                    closePost: (function (param) {
                        return Curry._1(dispatch, /* TogglePost */[
                                    currentPost,
                                    isPostOpened
                                  ]);
                      }),
                    currentPost: currentPost
                  }) : React.createElement(React.Fragment, undefined, React.createElement(Header.make, {
                        pageName: "blog"
                      }), React.createElement(ScrollView.make, {
                        id: "ScrollView_Blog",
                        scroll: scroll,
                        scrollTop: scrollTop,
                        children: null
                      }, React.createElement("main", {
                            style: {
                              margin: "4.8rem 0 20rem",
                              padding: "0 6%"
                            }
                          }, $$Array.map((function (post) {
                                  return React.createElement(Post.make, {
                                              clickPost: (function (param) {
                                                  return Curry._1(dispatch, /* TogglePost */[
                                                              post,
                                                              isPostOpened
                                                            ]);
                                                }),
                                              content: post[/* content */0],
                                              date: post[/* date */1],
                                              id: post[/* id */2],
                                              lastUpdate: post[/* lastUpdate */3],
                                              title: post[/* title */4],
                                              key: post[/* id */2]
                                            });
                                }), $$Array.of_list(Posts.posts))), React.createElement(Footer.make, { }))));
}

var make = Blog;

exports.make = make;
/* Post Not a pure module */
