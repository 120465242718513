// Generated by BUCKLESCRIPT VERSION 5.0.6, PLEASE EDIT WITH CARE
'use strict';

var Box = require("../components/Box.js");
var React = require("react");
var Header = require("../components/Header.js");
var Helpers = require("../data/Helpers.js");
var ScrollView = require("../components/ScrollView.js");

function color(color$1) {
  return {
          color: color$1,
          display: "inline-block",
          whiteSpace: "pre-wrap"
        };
}

function Misc(Props) {
  var scroll = Props.scroll;
  var scrollTop = Props.scrollTop;
  return React.createElement("div", {
              id: "Page_Misc"
            }, React.createElement(Header.make, {
                  pageName: "miscellaneous"
                }), React.createElement(ScrollView.make, {
                  id: "ScrollView_Misc",
                  scroll: scroll,
                  scrollTop: scrollTop,
                  children: React.createElement(Box.make, {
                        flexDirection: "column",
                        justifyContent: "center",
                        margin: "2rem auto",
                        padding: "2rem",
                        children: React.createElement("p", {
                              className: "info"
                            }, Helpers.str("Front-end written in "), React.createElement("a", {
                                  style: color("#ff9090"),
                                  href: "https://reasonml.github.io/",
                                  target: "_blank"
                                }, Helpers.str("ReasonML")), React.createElement("br", undefined), Helpers.str("Icons from "), React.createElement("a", {
                                  style: color("#dbb"),
                                  href: "https://feathericons.com/",
                                  target: "_blank"
                                }, Helpers.str("Feather Icons")), React.createElement("br", undefined), Helpers.str("Free fonts from "), React.createElement("a", {
                                  style: color("violet"),
                                  href: "https://www.fontsquirrel.com/",
                                  target: "_blank"
                                }, Helpers.str("Font Squirrel")))
                      })
                }));
}

var make = Misc;

exports.color = color;
exports.make = make;
/* Box Not a pure module */
